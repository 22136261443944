(function (factory) {
    typeof define === 'function' && define.amd ? define('index', factory) :
    factory();
}((function () { 'use strict';

    const stickyElem = document.querySelector(".tmr-sticky");
    const nav = document.querySelector("#nav");

    /* Gets the amount of height
    of the element from the
    viewport and adds the
    pageYOffset to get the height
    relative to the page */
    const currStickyPos = stickyElem.getBoundingClientRect().top + window.scrollY;
    window.onscroll = function () {
      /* Check if the current Y offset
      is greater than the position of
      the element */
      if (window.scrollY > currStickyPos) {
        stickyElem.style.position = "fixed";
        stickyElem.style.top = "0px";
        nav.classList.add('bg-white', 'border-bottom');
      } else {
        stickyElem.style.position = "absolute";
        stickyElem.style.top = "initial";
        nav.classList.remove('bg-white', 'border-bottom');
      }
    };

})));
